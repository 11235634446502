import React from "react";

import ButtonSolid from "../../components/Button/ButtonSolid";

import PortableText from "../../components/Blog/portableText";
import tw from "twin.macro";
import styled from "@emotion/styled";

const StyledContent = styled.div`
  ul {
    ${tw`list-disc pl-7 mb-6 flex flex-col space-y-0.5`}
  }
  ol {
    ${tw`list-decimal pl-7 mb-6 flex flex-col space-y-0.5`}
  }
`;

const NeighborhoodSection2 = ({
  headingLevel,
  className,
  title1,
  description1,
  title2,
  description2,
  title3,
  description3,
  title4,
  description4,
  title5,
  description5,
}) => {
  return (
    <>
      <section className={` ${className || "mb-20 md:mb-32"}`}>
        <div className="container">
          <div className="grid gap-y-10 md:gap-x-12 lg:gap-x-24">
            <div className="md:col-span-8 md:col-start-1">
              <div className="mb-12 md:mb-16">
                {title1 && <h2>{title1}</h2>}
                <StyledContent>
                  {description1 && <PortableText blocks={description1} />}
                </StyledContent>
              </div>

              <div className="mb-12 md:mb-16">
                {title2 && <h3>{title2}</h3>}
                <StyledContent>
                  {description2 && <PortableText blocks={description2} />}
                </StyledContent>
              </div>

              <div className="mb-12 md:mb-16">
                {title3 && <h3>{title3}</h3>}
                <StyledContent>
                  {description3 && <PortableText blocks={description3} />}
                </StyledContent>
              </div>

              <div>
                {title4 && <h3>{title4}</h3>}
                <StyledContent>
                  {description4 && <PortableText blocks={description4} />}
                </StyledContent>
                <ButtonSolid modal="modal-contact" text="Contact" />
              </div>
            </div>

            <div className="md:col-span-4 md:col-end-13">
              <div className="md: sticky top-32 rounded-3xl bg-tertiary-50 p-10">
                <div>
                  {title5 && <h3>{title5}</h3>}
                  <StyledContent>
                    {description5 && <PortableText blocks={description5} />}
                  </StyledContent>
                  <ButtonSolid modal="modal-contact" text="Book a Visit" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default NeighborhoodSection2;
