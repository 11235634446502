import React from "react";

import { StaticImage } from "gatsby-plugin-image";

import ButtonSolid from "../../components/Button/ButtonSolid";

const NeighborhoodSection1 = ({
  headingLevel,
  className,
  sectionTitle,
  sectionDesc,
}) => {
  return (
    <section className="relative mb-20 bg-tertiary-50 pt-12 md:mb-32 md:py-32 md:pt-0 lg:py-44">
      <div className="absolute bottom-0 left-0 hidden h-full w-full md:block">
        <StaticImage
          src="../../images/3.0 Neighborhood/Neighborhood.jpg"
          loading="eager"
          placeholder="none"
          className="h-full"
          imgClassName="object-top"
        />
      </div>

      <div className="container relative">
        <div className="grid gap-y-12">
          <div className="text-center md:max-w-[588px] md:text-left">
            <h1 className="md:text-white">{sectionTitle}</h1>
            <p className="md:text-white">{sectionDesc}</p>
            <ButtonSolid
              className="hidden md:block"
              modal="modal-contact"
              altStyle={2}
              text="Contact Us"
            />
            <ButtonSolid
              className="block md:hidden"
              modal="modal-contact"
              altStyle={1}
              text="Contact Us"
            />
          </div>

          <div className="-mx-4 md:hidden">
            <StaticImage
              src="../../images/3.0 Neighborhood/1.0 Neighborhood-mobile.jpg"
              loading="eager"
              placeholder="none"
              className="h-full w-full"
              imgClassName="object-top"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default NeighborhoodSection1;
